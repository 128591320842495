import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
import Smsalert from "./Smsalert";

const Home = () => {
  //TODO:OFFSET
  const offset = 110;
  const cards = [];
  const options = [];

  let [mqttdata, setMqttData] = useState([]);
  let [kajal, setKajal] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
  kajal = JSON.parse(localStorage.getItem("kajal"));

  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);

    let kajal = JSON.parse(localStorage.getItem("kajal"));
    setKajal(kajal);
    // }, [mqttdata]);
  }, 5000);

  // console.log(mqttdata);

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  // console.log(imeilist);

  let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  // console.log(device_ids);

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  let name_list = JSON.parse(localStorage.getItem("name_list"));

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }

  for (let i = 0; i < imeilist.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className="flex-grow w-auto h-auto max-w-sm max-h-full p-5 m-2 mt-2 overflow-hidden transition-shadow ease-in bg-white bg-opacity-25 border border-gray-300 border-opacity-50 rounded-lg shadow-lg cursor-pointer sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 shadow-cyan-600 hover:shadow-4xl hover:shadow-stone-900 backdrop-filter backdrop-blur-lg">
        <div className="mb-2 border-b-4 border-blue-950">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}>
            {/* <div className="pb-3 font-mono text-xl font-semibold text-center text-white">
              {name_list[i]}
            </div> */}
            <div className="pb-3 font-mono text-xl font-semibold text-center text-white">
              {location_list[i]}
            </div>
            <h3 className="pb-3 font-mono text-xl font-semibold text-center text-white">
              ({imeilist[i]})
            </h3>
          </Link>
        </div>

        {/* <div className="flex flex-wrap items-center justify-center">
        <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            PUMP
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[7] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[7] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[7] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL1
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[0] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[0] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[0] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL2
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[1] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[1] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[1] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
        </div>

        <div className="flex flex-wrap items-center justify-center ">
        <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL3
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[2] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[2] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[2] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL4
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[3] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[3] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[3] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL5
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[4] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[4] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[4] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
        </div>

        <div className="flex flex-wrap">
        <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL6
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[5] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[5] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[5] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
          <p className="p-0.5 m-1 bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none block text-black font-bold text-center flex-1 text-sm">
            VL7
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className={`p-1 rounded-full border border-green-700 ${
                  kajal?.[6] === 1 ? "bg-green-700" : "bg-red-700"
                } shadow-lg`}>
                <button className="px-5"></button>
              </div>
              <span
                className={`text-xs mt-1 ${
                  kajal?.[6] === 1 ? "text-green-700" : "text-red-700"
                }`}>
                {kajal?.[6] === 1 ? "ON" : "OFF"}
              </span>
            </div>
          </p>
        </div> */}

        {/* <div className="flex flex-wrap">
          <p
            className={`flex flex-col items-center flex-1 p-1 m-1 justify-center  bg-gray-200 rounded-lg shadow-xl border-2 border-blue-950 transition duration-300 ease-in-out focus:outline-none text-black font-bold text-center text-sm `}
          >
            DEVICE STATUS
            <button
              className={`flex items-center flex-1 p-2 m-1 justify-center rounded-lg shadow-md ${
                (mqttdata?.[i] ? mqttdata[i][offset + 32] : 0 === 1)
                  ? "bg-green-400 text-white hover:bg-green-500"
                  : "bg-red-500 text-white hover:bg-red-600"
              } transition-colors ease-in-out duration-200`}
            >
              {(mqttdata?.[i] ? mqttdata[i][32] : 0 === 1) ? "ON" : "OFF"}
            </button>
          </p>
        </div> */}
      </div>,
    );
  }

  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  return (
    <div className="mt-0 ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex flex-wrap justify-center gap-6 p-2 font-sans font-semibold text-center bg-indigo-800">
        <nav className="text-xl text-white">
          TEMPERATURE DATA LOGGER & ALARM SYSTEM
        </nav>
        <div className="">
          <Select
            options={options}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="min-h-screen overflow-hidden bg-gradient-to-br from-indigo-600 via-indigo-400 to-purple-400">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>

      {/* <div>
        <Smsalert />
      </div> */}
    </div>
  );
};

export default Home;
